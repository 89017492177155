import { Typography as AlliumTypography, TypographyProps } from '@telus-uds/components-web'
import { SpacerProps } from '../Spacer/Spacer'
import { TextRenderer } from '../../../utils/text/TextRenderer'
import { renderSpacer } from 'src/siteBuilder/utils/renderSpacer'
import { VariantColor, VariantSize } from '../RichText/TypographyVariantTypes'

export interface TextProps extends TypographyProps {
  text: string
  colour?: VariantColor
  inverse?: boolean
  size?: VariantSize
  bold?: boolean
  strikethrough?: boolean
  spacer?: SpacerProps
}

const Text = ({
  text,
  size,
  colour,
  inverse = false,
  align,
  bold = false,
  strikethrough = false,
  spacer,
  ...rest
}: TextProps) => {
  const fieldPath = rest['data-sb-field-path'] as string
  const dataSet = {}
  if (fieldPath) {
    dataSet['sb-field-path'] = `${fieldPath} ${fieldPath}.text`
  }

  return (
    <div data-testid="text-testid">
      {renderSpacer(spacer)}
      <AlliumTypography
        variant={{
          size,
          colour,
          inverse,
          bold,
        }}
        block={true}
        align={align}
        // @@TODO: Remove ts-ignore once UDS package update is done
        // @ts-ignore
        strikeThrough={strikethrough}
        dataSet={dataSet}
      >
        <TextRenderer>{text}</TextRenderer>
      </AlliumTypography>
    </div>
  )
}

export default Text
